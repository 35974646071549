import React, { Component } from 'react';
import Autocomplete from  'react-autocomplete';
import { getStocks, matchStocks } from './data';
import './autocomplete.css';

class Autocomplete_example extends Component {
	constructor(props){
        super(props);
		// this.getStocks = this.getStocks.bind(this);
		// this.matchStocks = this.matchStocks.bind(this);
        this.state = {
            data: this.props.dataParentToChild
        }
    }

  state = { value: '' };
  
  // data.js

// getStocks() {
// 	let items = []
// 	this.props.items.map(item=>items.push({name: item[this.props.what], abbr: item[this.props.what]}))
// 	return items
//   }

//   // data.js

// matchStocks(state, value) {
// 	return (
// 	  state.name.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
// 	  state.abbr.toLowerCase().indexOf(value.toLowerCase()) !== -1
// 	);
//   }

  render() {
	// console.log('items:',getStocks(this.props.items, this.props.what) )
  // console.log('value:',[this.state.value],(this.state.value)?"defined":"undefined")
    return (
    //   <div style = {{width:"100%"}}>
        <Autocomplete className="" style={{width:"100%"}}
          value={ this.state.value }
          inputProps={{ id: 'states-autocomplete',className:"form-control"}}
          wrapperStyle={{ position: 'relative', display: 'inline-block', width:"100%" }}
          items={ getStocks(this.props.items, this.props.what) }
          getItemValue={ item => item.name }
          shouldItemRender={ matchStocks }
          onChange={(event, value) => {
			  						this.setState({ value });
										this.props.onChange(event,this.props.whatOnChange);
										this.props.final_checks_clear(this.props.whatOnClear)
		  								}
									}
          onSelect={ value => {this.setState({ value });
                              this.props.onChange(value,this.props.whatOnChange,'yes');
                              this.props.final_checks_clear(this.props.whatOnClear)
                    }}
          renderMenu={ children => (
            (this.state.value)?
            <div className = "menu scroll_custom " style={{overflowX:"hidden", maxHeight:"10em"}}>
              { children }
            </div>
            :<></>
          )}
          renderItem={ (item, isHighlighted) => (
            <div
              className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
              key={ item.abbr } >
              { item.name }
            </div>
          )}
        />
    //   </div>
      );
    }
  }

export default Autocomplete_example;
