import React, { Component } from "react";
import DataService from "../services/tutorial.service";
import { Redirect } from 'react-router';
import Table from 'react-bootstrap/Table'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Link } from "react-router-dom";

import "./../components/styles.css";
import SweetAlert from 'react-bootstrap-sweetalert';

import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { FaGem, FaHeart, FaAlgolia, FaCheck} from "react-icons/fa";
import { Container } from "react-bootstrap";

import Table_template from "./table.hook";
import Filter_menu from "./filters.hook";
import BootstrapSwitchButton from 'bootstrap-switch-button-react'

import custom_os_aop from '../controls/custom_os_aop.variable';
const {arrangePersons,isDate,compose_header_personal_profiles} = require('../controls/functions')


export default class Filters extends Component {
  constructor(props) {
    super(props);
    this.componentWillMount = this.componentWillMount.bind(this);
    this.future_true_dates = this.future_true_dates.bind(this);
    // this.future_pos_diff = this.future_pos_diff.bind(this);
    // this.arrangePersons = this.arrangePersons.bind(this);
    this.chooseFilters = this.chooseFilters.bind(this);
    this.filter_firmy_vykonavaSa = this.filter_firmy_vykonavaSa.bind(this);
    this.filter_firmy_vyznamy = this.filter_firmy_vyznamy.bind(this);
    this.filter_firmy_pridelenie1 = this.filter_firmy_pridelenie1.bind(this);
    this.filter_firmy_pridelenie2 = this.filter_firmy_pridelenie2.bind(this);
    
    this.filter_osoby_pridelenie1 = this.filter_osoby_pridelenie1.bind(this);
    this.filter_osoby_pridelenie2 = this.filter_osoby_pridelenie2.bind(this);
    this.filter_osoby_firmy = this.filter_osoby_firmy.bind(this);
    this.filter_osoby_courses = this.filter_osoby_courses.bind(this);
    this.filter_osoby_datum = this.filter_osoby_datum.bind(this);
    // this.checkDates = this.checkDates.bind(this);
    // this.stringToTime = this.stringToTime.bind(this);
    // this.withinInterval = this.withinInterval.bind(this);
    this.setConditionDict = this.setConditionDict.bind(this);
    this.changeAddColumns = this.changeAddColumns.bind(this);


    this.color = "white"
    this.state = {
      terminyDataAddOns: {},
      fullFetch: false,
      filter_profiles: false,
      filter_companies: false,
      filter_technicians: false,
      firmy_vykonavaSa: [],
      firmy_vyznamy: [],
      firmy_pridelenie1: [],
      firmy_pridelenie2: [],
      
      osoby_pridelenie1: [],
      osoby_pridelenie2: [],
      osoby_firmy: [],
      osoby_courses: [],
      osoby_datumy: [],
      additionalColumns: {
        addAddress: true
      }
    };
  }

  componentWillMount() {
    console.log('in WillMount-getAllCourses');

    this.setConditionDict()

    // and now update whole state in case of any changes:
    DataService.getAllTechnicians()
      .then(response => {
        console.log("received technicians data:",response.data);
        this.setState({
          message: "The techicians were obtained successfully!"
        });
        this.setState({
          allTechnicians: response.data[0]
        });
      })
      .then(()=>{
        let technicians = []
        console.log('this state:',this.state)
        this.state.allTechnicians && this.state.allTechnicians.map(item => technicians.push(item.technician_priezvisko + " " + item.technician_meno))
        this.setState({techArray: technicians})
      })
      .catch(e => {
        console.log('The technicians fetch failed!',e);
      });


    DataService.getAllCourses()
      .then(response => {
        console.log("received data",response.data);
        this.setState({
          allCourses: response.data[0]
        });
      })
      .catch(e => {
        console.log('The courses fetch failed!',e);
      });

    DataService.getWholeDatabase()
      .then(response => {
        // this.setState({database: response.data},function() {this.build_tree()})
        console.log('response darta:',response.data)
        this.setState({database: response.data})
        console.log("received full database data",response.data);

        var skolenia2 = []
        const skolenia = response.data.filter(function(table){return table['table_name']==='skolenies'})[0]['data']
        skolenia && console.log('skolenia:',skolenia)
        skolenia && skolenia.map(skolenie=>{
          var skol = JSON.parse(skolenie['skolenie_dict'])
          if (skol) skol['skolenie_id'] = skolenie['skolenie_id']
          skolenia2.push(skol)
        })
        this.setState({skolenia: skolenia2})

        let company_names = []
        let company_inactive=[]
        const companies = response.data.filter(function(table){return table['table_name']==='companies'})[0]['data']
        companies.map(company=>company_names.push(company.company_nazov))
        companies.map(company=>{if (company.active===false) {company_inactive.push(company.company_nazov)}})
        this.setState({allCompanies: company_names,allCompaniesInactive: company_inactive})

        let course_names = []
        const courses = response.data.filter(function(table){return table['table_name']==='courses'})[0]['data']
        courses.map(course=>course_names.push(course.course_nazov))
        this.setState({allCourses_names: course_names})
      })
      .then(()=>{
        this.setState({fullFetch: true})
        this.chooseFilters(true,false,false)
      })
      .catch(e => {
        console.log('The full data fetch failed!',e);
      });
  }

  setConditionDict(){
    let days={
      1: 31,
      2: 29,
      3: 31,
      4: 30,
      5: 31,
      6: 30,
      7: 31,
      8: 31,
      9: 30,
      10: 31,
      11: 30,
      12: 31,
    }
    let conditions_dicts = {
      'zmeškané do dnes': {'lower':'00.00.0000','upper':'23.01.2022'},
      'aktuálny mesiac': {'lower':'00.00.0000','upper':'23.01.2022'},
      'aktuálny+nasledujúci mesiac': {'lower':'00.00.0000','upper':'23.01.2022'},
      'aktuálny+nasledujúce 2 mesiace': {'lower':'00.00.0000','upper':'23.01.2022'},
      'tento rok': {'lower':'00.00.0000','upper':'23.01.2022'},
      'neohraničené': {'lower':'00.00.0000','upper':'31.12.9999'},
    }
    let today = new Date()
    let day_now = today.getDate()
    let month_now = today.getMonth()+1
    let year_now = today.getFullYear()
    const month_nowStr = (month_now>9)?String(month_now):'0'+String(month_now)
    const month_plus1Str = (month_now+1>9)?String(month_now+1):'0'+String(month_now+1)
    const month_plus2Str = (month_now+2>9)?String(month_now+2):'0'+String(month_now+2)
    const day_nowStr = (day_now>9)?String(day_now):'0'+String(day_now)
    const todayStr = [day_nowStr,month_nowStr,year_now].join('.')
    const startOfActualMonth = ['01',month_nowStr,year_now].join('.')
    const endOfActualMonth = [days[month_now],month_nowStr,year_now].join('.')
    
    const endOfPlus1Month = (month_plus1Str === '13')?[days[1],'01',year_now+1].join('.'):[days[month_now+1],month_plus1Str,year_now].join('.')
    const endOfPlus2Month = (month_plus2Str === '13')?[days[1],'01',year_now+1].join('.'):(
      (month_plus2Str === '14')?[days[2],'02',year_now+1].join('.'):[days[month_now+2],month_plus2Str,year_now].join('.')
    )
    
    const startOfActualYear = ['01','01',year_now].join('.')
    const endOfActualYear = ['31','12',year_now].join('.')
    // console.log('times:',todayStr,month_nowStr,month_plus1Str,month_plus2Str)

    conditions_dicts['zmeškané do dnes'].lower = '00.00.0000'
    conditions_dicts['zmeškané do dnes'].upper = todayStr
    conditions_dicts['aktuálny mesiac'].lower = startOfActualMonth
    conditions_dicts['aktuálny mesiac'].upper = endOfActualMonth
    conditions_dicts['aktuálny+nasledujúci mesiac'].lower = startOfActualMonth
    conditions_dicts['aktuálny+nasledujúci mesiac'].upper = endOfPlus1Month
    conditions_dicts['aktuálny+nasledujúce 2 mesiace'].lower = startOfActualMonth
    conditions_dicts['aktuálny+nasledujúce 2 mesiace'].upper = endOfPlus2Month
    conditions_dicts['tento rok'].lower = startOfActualYear
    conditions_dicts['tento rok'].upper = endOfActualYear
    // console.log('updated conditionDict:',conditions_dicts)
    this.setState({conditions_dicts:conditions_dicts})
  }

  arrangeTechnicians(){
    const techicians = this.state.fullFetch && this.state.fullFetch===true && this.state.database.filter(function(table){return table['table_name'] === 'technicians'})[0]['data']
    return techicians.sort((a, b) => {if (a.technician_priezvisko !== b.technician_priezvisko) {return a.technician_priezvisko > b.technician_priezvisko ? 1:-1} else { return a.technician_meno > b.technician_meno ? 1:-1}})
  }

  arrangeCompanies(){
    let companies = this.state.fullFetch && this.state.fullFetch===true && this.state.database.filter(function(table){return table['table_name'] === 'companies'})[0]['data']
    companies = companies.filter(item=>item.active===true)
    console.log('companies',companies)
    
    //add filter vykonavaSa
    if (this.state.firmy_vykonavaSa.length>0){
      companies = companies.filter(company=>
        // this.state.firmy_vykonavaSa.every(v=> JSON.parse(company.company_vykonava_sa).includes(v)) //intersection
        this.state.firmy_vykonavaSa.some(v=> JSON.parse(company.company_vykonava_sa).indexOf(v)>=0) //union
      )
    }

    // add filter vyznamy
    if (this.state.firmy_vyznamy.length>0){
      companies = companies.filter(company=>
        this.state.firmy_vyznamy.includes(this.num2lettes(company.company_vyznam))
      )
    }

    // add filter pridelenie1
    if (this.state.firmy_pridelenie1.length>0){
      console.log("pridelenie1",this.state.firmy_pridelenie1)
      console.log('map:',companies.map(company => company.company_pridelenie1.split(" ")[1] + " " + company.company_pridelenie1.split(" ")[0]))
      const pridelenie1 = this.state.firmy_pridelenie1
      companies = companies.filter(company=>
        pridelenie1.includes(company.company_pridelenie1.split(" ")[1] + " " + company.company_pridelenie1.split(" ")[0])
      )
    }
    // add filter pridelenie2
    if (this.state.firmy_pridelenie2.length>0){
      console.log("pridelenie2",this.state.firmy_pridelenie2)
      console.log('map:',companies.map(company => company.company_pridelenie2.split(" ")[1] + " " + company.company_pridelenie2.split(" ")[0]))
      const pridelenie2 = this.state.firmy_pridelenie2
      companies = companies.filter(company=>
        pridelenie2.includes(company.company_pridelenie2.split(" ")[1] + " " + company.company_pridelenie2.split(" ")[0])
      )
    }
    let companies_update = JSON.parse(JSON.stringify(companies))
    for (let i=0;i<companies_update.length;i++){
      console.log('hello2:',companies_update[i].company_vyznam,this.num2lettes(companies_update[i].company_vyznam))
      companies_update[i].company_vyznam = (this.num2lettes(companies_update[i].company_vyznam))?this.num2lettes(companies_update[i].company_vyznam):""
      let num = 0
      let personalProfiles = this.state.fullFetch && this.state.fullFetch===true && this.state.database.filter(function(table){return table['table_name'] === 'personal_details'})[0]['data']
      personalProfiles.map(person=>{if (person['zamestnávateľ']===companies_update[i].company_nazov) num += 1})
      companies_update[i]['pocet_zamestnancov'] = num
    }
    // companies = companies.forEach(item=>item.company_vyznam = this.num2lettes(item.company_vyznam))
    console.log('companies after forEach:',companies_update)
    return companies_update.sort((a, b) => a.company_nazov > b.company_nazov ? 1:-1)
  }

  num2lettes(num){
    if (num==='1'){return 'A'}
    if (num==='2'){return 'B'}
    if (num==='3'){return 'C'}
    if (num==='4'){return 'D'}
    if (num==='5'){return 'E'}
  }

  letters2num(letter){
    if (letter==='A'){return '1'}
    if (letter==='B'){return '2'}
    if (letter==='C'){return '3'}
    if (letter==='D'){return '4'}
    if (letter==='E'){return '5'}
  }

  future_true_dates(date){
    //returns 1 if date is in future and -1 if in the past

    if (!isDate(date)) return "wrong format"
    let today = new Date()
    let day_now = today.getDate()
    let month_now = today.getMonth()+1
    let year_now = today.getFullYear()

    let date_split  =date.split('.')
    let day_check = Number(date_split[0])
    let month_check = Number(date_split[1])
    let year_check = Number(date_split[2])
    console.log('now:',day_now,month_now,year_now)
    console.log('check:',day_check,month_check,year_check)

    if (year_now > year_check) return -1
    if (year_now < year_check) return 1
    if (year_now === year_check) {
      if (month_now > month_check) return -1
      if (month_now < month_check) return 1
      if (month_now === month_check) {
        if (day_now > day_check) return -1
        if (day_now < day_check) return 1
        if (day_now === day_check) return 1
      }
    }
  }


  chooseFilters(filter_profiles_bool,filter_companies_bool,filter_technicians_bool){
    // console.log('in chooseFilters: ',filter_profiles_bool, filter_companies_bool, filter_technicians_bool)
    this.setState({filter_profiles: filter_profiles_bool,filter_companies: filter_companies_bool,filter_technicians: filter_technicians_bool})
  }


  //filter functions
  filter_firmy_vykonavaSa(selection){
    let vykonavaSa = []
    selection.map(item=>vykonavaSa.push(item.value))
    // console.log('vykonava sa:',vykonavaSa)
    this.setState({firmy_vykonavaSa: vykonavaSa})
  }

  //filter functions
  filter_firmy_vyznamy(selection){
    let vyznamy = []
    selection.map(item=>vyznamy.push(item.value))
    // console.log('vyznamy:',vyznamy)
    this.setState({firmy_vyznamy: vyznamy})
  }

  //filter functions
  filter_firmy_pridelenie1(selection){
    let pridelenie1 = []
    selection.map(item=>pridelenie1.push(item.value))
    // console.log('pridelenie1:',pridelenie1)
    this.setState({firmy_pridelenie1: pridelenie1})
  }

  //filter functions
  filter_firmy_pridelenie2(selection){
    let pridelenie2 = []
    selection.map(item=>pridelenie2.push(item.value))
    // console.log('pridelenie2:',pridelenie2)
    this.setState({firmy_pridelenie2: pridelenie2})
  }

  //filter functions
  filter_osoby_pridelenie1(selection){
    let pridelenie1 = []
    selection.map(item=>pridelenie1.push(item.value))
    // console.log('pridelenie1:',pridelenie1)
    this.setState({osoby_pridelenie1: pridelenie1})
  }

  //filter functions
  filter_osoby_pridelenie2(selection){
    let pridelenie2 = []
    selection.map(item=>pridelenie2.push(item.value))
    // console.log('pridelenie2:',pridelenie2)
    this.setState({osoby_pridelenie2: pridelenie2})
  }

  filter_osoby_firmy(selection){
    let firmy = []
    selection.map(firma=>firmy.push(firma.value))
    // console.log('firmy:',firmy)
    this.setState({osoby_firmy: firmy})
  }

  filter_osoby_courses(selection){
    let courses = []
    selection.map(item=>courses.push(item.value))
    // console.log('courses:',courses)
    this.setState({osoby_courses: courses})
  }

  filter_osoby_datum(selection,manualLow=null,manualHigh=null){
    let dates = []
    selection.map(item=>dates.push(item.value))
    console.log('datumy:',dates)
    this.setState({osoby_datumy: dates})
    if (manualLow && manualHigh){
      let conditions_dicts_tmp = this.state.conditions_dicts
      let dateLow = manualLow.getDate()
      let monthLow = manualLow.getMonth()+1
      let yearLow = manualLow.getFullYear()
      let dateHigh = manualHigh.getDate()
      let monthHigh = manualHigh.getMonth()+1
      let yearHigh = manualHigh.getFullYear()
      const dateLowCorr = (dateLow>9)?String(dateLow):'0'+String(dateLow)
      const monthLowCorr = (monthLow>9)?String(monthLow):'0'+String(monthLow)
      const dateHighCorr = (dateHigh>9)?String(dateHigh):'0'+String(dateHigh)
      const monthHighCorr = (monthHigh>9)?String(monthHigh):'0'+String(monthHigh)
      console.log('dates:',dateLowCorr,monthLowCorr,yearLow,dateHighCorr,monthHighCorr,yearHigh,[dateLowCorr,monthLowCorr,yearLow].join('.'))
      conditions_dicts_tmp['zvoľ dátum'] = {}
      conditions_dicts_tmp['zvoľ dátum'].lower = [dateLowCorr,monthLowCorr,yearLow].join('.')
      conditions_dicts_tmp['zvoľ dátum'].upper = [dateHighCorr,monthHighCorr,yearHigh].join('.')
      this.setState({conditions_dicts: conditions_dicts_tmp})
    }
  }

  changeAddColumns(additionalColumns){
    this.setState({additionalColumns: additionalColumns})
  }

  render() {
    console.log('this.state in terminyBase: ',this.state)
    console.log('database loaded: ',this.state.fullFetch)
    console.log('custom_os_aop:',custom_os_aop)
    const filtre_firmy = {
      'Vykonáva sa': this.state.firmy_vykonavaSa,
      'Významy': this.state.firmy_vyznamy,
      'Pridelenie 1': this.state.firmy_pridelenie1,
      'Pridelenie 2': this.state.firmy_pridelenie2,
    }

    const filtre_osoby = {
      'Firmy': this.state.osoby_firmy,
      'Pridelenie 1': this.state.osoby_pridelenie1,
      'Pridelenie 2': this.state.osoby_pridelenie2,
      'Kurzy':this.state.osoby_courses,
      'V období':this.state.osoby_datumy,
    }
    
    
  return (
    <div className="row w-100">
      <Col xs={4} xl={4}>
        {(this.state.techArray && this.state.allCompanies && this.state.allCompaniesInactive && this.state.allCourses_names)?
          
          <>
            <br/>
            <Filter_menu 
              chooseFilters = {this.chooseFilters} 
              technici = {this.state.techArray}
              companies = {this.state.allCompanies}
              companiesInactive = {this.state.allCompaniesInactive}
              courses = {this.state.allCourses_names}
              coursesSelected = {this.state.osoby_courses}
              filter_osoby_pridelenie1 = {this.filter_osoby_pridelenie1}
              filter_osoby_pridelenie2 = {this.filter_osoby_pridelenie2}
              filter_osoby_firmy = {this.filter_osoby_firmy}
              filter_osoby_courses = {this.filter_osoby_courses}
              filter_osoby_datumy = {this.filter_osoby_datum}

              filter_firmy_vykonavaSa = {this.filter_firmy_vykonavaSa}
              filter_firmy_vyznamy = {this.filter_firmy_vyznamy}
              filter_firmy_pridelenie1 = {this.filter_firmy_pridelenie1}
              filter_firmy_pridelenie2 = {this.filter_firmy_pridelenie2}
              
            />
          </>
          :
          <div className="row w-100">
            <div className="col-xl-4 col-4">
              <div class="d-flex justify-content-center">
                <div className="spinner-border" role="status" style={{color:'grey', marginTop: '3em'}}>
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
            
            <div className="col-xl-8 col-8">
            </div>
          </div>
          }
      
      </Col>

      <Col xs={8} xl={8}>
        
        
        

        {(this.state.filter_profiles)?
        <Row className = "">
          <Table_template 
            headers = {//(this.state.osoby_datumy.length>0)?[{'priezvisko':'Priezvisko'}, {'meno': 'Meno'}, {'dátum narodenia':  'Dátum narodenia'}, {'zamestnávateľ': 'Zamestnávateľ'},{'profesia': 'Profesia'},{'what':'Typ školenia'},{'which':'Ev. č. preukazu (kurz, ak nezadané)'},{'when':'Dátum vypršania platnosti'},{'whom':'Kurz'}]:
            // [{'priezvisko':'Priezvisko'}, {'meno': 'Meno'}, {'dátum narodenia':  'Dátum narodenia'}, {'zamestnávateľ': 'Zamestnávateľ'},{'profesia': 'Profesia'}]} 
            compose_header_personal_profiles(this.state.osoby_courses,this.state.allCourses,this.state.additionalColumns)}
            items = {arrangePersons(this.state.fullFetch,this.state.database,this.state.osoby_firmy,this.state.osoby_pridelenie1,this.state.osoby_pridelenie2,this.state.osoby_courses,this.state.osoby_datumy,this.state.allCourses,this.state.conditions_dicts)}
            type = 'Osoby'
            filtre = {filtre_osoby}
            changeAddAddress = {this.changeAddColumns}
          />
        </Row>:null}

        {(this.state.filter_technicians)?
        <Row className = "">
          <Table_template 
            headers = {[{'technician_priezvisko':'Priezvisko'}, {'technician_meno': 'Meno'}, {'technician_email': 'Email'}]} 
            items = {this.arrangeTechnicians()}
            type = 'Technici'
            changeAddAddress = {this.changeAddColumns}
          />
        </Row>:null}

        {(this.state.filter_companies)?
        <Row className = "">
          <Table_template 
            headers = {[{'company_nazov': 'Názov'}, {'company_ico':'IČO'}, {'company_mesto': 'Mesto'}, {'company_ulica': 'Adresa'}, {'company_psc': 'PSČ'}, 
            {'company_statutar': 'Štatutár'}, {'company_email': 'Email (štatutár)'}, {'company_mobil': 'Tel. číslo (štatutár)'},  
            {'company_kontakt': 'Kontaktná osoba'}, {'company_kontakt_email': 'Email (k. osoba)'}, {'company_kontakt_mobil': 'Tel. číslo (k. osoba)'},
            {'pocet_zamestnancov': 'Počet zamestnancov'}, {'company_vykonava_sa': 'Vykonáva sa'}, {'company_velkost': 'Veľkosť'}, {'company_vyznam': 'Význam'}, {'company_pridelenie1': 'Pridelenie'}, {'company_poznamka': 'Poznámka'},
          ]} 
            items = {this.arrangeCompanies()}
            type = 'Firmy'
            filtre = {filtre_firmy}
            changeAddAddress = {this.changeAddColumns}
            // showFilters = {true}
          />
        </Row>:null}
      
      </Col>

      {/* <Col xs={2} xl={2}>
      
      </Col> */}

    </div>
    )
  }
}